// export const customSchema = (schema) => {
//   const fixedSchema = schema
//     .replace(/18.214.164.211/g, "www.myhealthinsurance.com")
//     .replace(/"item":"\/"/gi, `"item":"https://www.myhealthinsurance.com/"`)
//     .replace(
//       /\/wp-content\/uploads\/2022\/02\/logo.png/gi,
//       "/mhi-logo-schema.png"
//     );
//   // console.log(fixedSchema);
//   return JSON.parse(fixedSchema);
// };
export const customSchema = (schema) => {
  const fixedSchema = schema
      // Replace specific IP address with domain
      .replace(/18.214.164.211/g, "www.myhealthinsurance.com")
      // Ensure the root item is an absolute URL
      .replace(/"item":"\/"/gi, `"item":"https://www.myhealthinsurance.com/"`)
      // Ensure the logo URL is updated correctly
      .replace(/\/wp-content\/uploads\/2022\/02\/logo.png/gi, "/mhi-logo-schema.png")
      // Fix any relative URLs
      .replace(/"item":"\/(.*?)"/gi, `"item":"https://www.myhealthinsurance.com/$1"`)
      .replace(/"@id":"\/(.*?)"/gi, `"@id":"https://www.myhealthinsurance.com/$1"`)
      .replace(/"url":"\/(.*?)"/gi, `"url":"https://www.myhealthinsurance.com/$1"`)
      .replace(/"thumbnailUrl":"\/(.*?)"/gi, `"thumbnailUrl":"https://www.myhealthinsurance.com/$1"`)
      .replace(/"contentUrl":"\/(.*?)"/gi, `"contentUrl":"https://www.myhealthinsurance.com/$1"`)
      .replace(/"target":"\/(.*?)"/gi, `"target":"https://www.myhealthinsurance.com/$1"`)
      .replace(/"target":\["\/(.*?)"\]/gi, `"target":["https://www.myhealthinsurance.com/$1"]`);

  // console.log(fixedSchema);
  return JSON.parse(fixedSchema);
};